.errorText {
  color: var(--sore500);
}

.sidepanelContentWrapper {
  gap: 0;

  p {
    max-width: 145px;
  }
}